import React, { useState, useEffect } from 'react';
import {
  Button, Card, Checkbox, Dropdown, Menu, Modal, Tag, Tooltip,
} from 'antd';
import StarRatings from 'react-star-ratings';
import { stringify, parse } from 'query-string';
import { useHistory } from 'react-router-dom';
import { Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip as cTooltip,
  Legend,
} from 'chart.js';
import ReadMore from '../../../common/components/ReadMore';
import BarChart from '../../../common/components/BarChart';
import Comment from '../Comment';
import {
  getConcatenatedString, getFormattedDate, getCookie, isViewOnlyAccess,
} from '../../../utils';
import Bookmark from '../../../common/components/Bookmark';
import './template.scss';
import ScoreModal from '../ScoreModal';
import PieChart from '../../../common/components/PieChart';
import InputWithCopy from '../CopyToClipboard';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, cTooltip, Legend);

const tagTitle = {
  tier: 'Tier',
  compliance: 'Compliance',
  alliance: 'Alliance type',
  indications: 'Indications',
  total_number_of_popis: 'Total PIs',
  industry_studies: 'Industry studies',
  interventional_studies: 'Interventional studies',
  observational_studies: 'Observational studies',
  parexel_studies: 'Parexel studies',
  enrollment_subjects: 'Enrolled subjects',
  department: 'Department',
  usn_id: 'USN',
};

const Template = ({
  type,
  itm,
  index,
  IsModalProps,
  checkboxVal,
  handleCheckboxVal,
  setTierClicked,
  tierClicked,
  selectedPiId,
  setSelectedPiId,
  projectsData,
  fromSearchResult,
  centerForMerging,
  setCenterForMerging,
  piForMerging,
  setPiForMerging,
  handleMergeClicked,
  setSelectedFromSearchPI,
  selectedFromSearchPI,
  fromUploadSponsor,
}) => {
  const history = useHistory();
  const [openKOLModal, setKOLModal] = useState(false);
  const adminType = getCookie('permissions');
  const [tabName, setTabName] = useState('');
  const [pieChartData, setPieChartData] = useState({});
  const [openEmergingDataModal, setEmergingDataModal] = useState(false);

  const handleClick = (item) => {
    if ((item.id && item.id.length) || (item.analysis_id && item.analysis_id.length)) {
      let params = {
        ...parse(location.search),
      };
      if (item.type === 'Project' || type === 'analysis') {
        let accessType = 'None';
        let shareType = '';
        let sharedProject = false;
        let view = false;
        let saveProcess = false;
        const isValid = 'true';
        if (
          projectsData
          && projectsData.flag
          && projectsData.data.data
          && projectsData.data.data.length
        ) {
          const filteredList = projectsData.data.data.filter(
            (prj) => prj.project_id === item.project_id && prj.owner_user_id !== getCookie('userName'),
          );
          if (filteredList.length) {
            accessType = filteredList[0].shared_details.access_type;
            shareType = filteredList[0].share_type;
            sharedProject = true;
          } else if (item.user_name !== `${getCookie('name')} ${getCookie('lastName')}`) {
            shareType = 'None';
            view = item.view;
            saveProcess = item.saveProcess;
          }
        }
        params = {
          ...params,
          project_id: item.project_id,
          isValid,
          project_name: type === 'analysis' ? item.project_name : item.name,
          access_type:
            item.user_name === `${getCookie('name')} ${getCookie('lastName')}`.trim()
              ? undefined
              : accessType,
          share_type:
            item.user_name === `${getCookie('name')} ${getCookie('lastName')}`.trim()
              ? undefined
              : shareType,
          shared_project:
            item.user_name === `${getCookie('name')} ${getCookie('lastName')}`.trim()
              ? undefined
              : sharedProject,
          view,
          saveProcess,
          innoplexus_id: itm?.iid,
        };
      } else {
        params = {
          ...params,
          id: item.id,
          type: item.type,
          currentTab: item.parent_flag ? 'parent_centers' : 'centers',
        };
      }

      if (item.type === 'Project' || type === 'analysis') {
        const win = window.open(`/projects/projectdetails?${stringify(params)}`, '_blank');
        win.focus();
      } else if (item.type === 'PI') {
        history.push(`/search/investigatorprofile?${stringify(params)}`);
      } else if (item.type === 'Network') {
        history.push(`/search/networkdetails?${stringify(params)}`);
      } else {
        history.push(`/search/deepdives?${stringify(params)}`);
      }
    }
  };

  const showPieChart = (chartData) => {
    return (
      chartData
      && !chartData?.every((item) => {
        return item.value === 0;
      })
    );
  };

  useEffect(() => {
    if (type === 'PI') {
      if (itm.kol_score.length) {
        setTabName(itm.kol_score[0].therapeutic_area);
      }
      if (Object.keys(itm.pie_chart).length) {
        setPieChartData(itm.pie_chart);
      }
    }
  }, [type]);

  const onChange = (e) => {
    handleCheckboxVal(index, e.target.checked);
  };

  const options = {
    plugins: {
      title: {
        display: false,
      },
    },
    responsive: true,
    interaction: {
      mode: 'index',
      intersect: false,
    },
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
      },
    },
  };

  const handleCenterMenuClick = (record) => {
    setCenterForMerging({
      isCenterSelected: true,
      centerOneId: record.id,
      centerOneData: record,
    });
  };

  const handlePiMenuClick = (record) => {
    setPiForMerging({
      isPiSelected: true,
      piOneId: record.id,
      piOneData: record,
    });
  };

  const handleCenterTwoSelected = (record) => {
    if (centerForMerging?.centerTwoId && centerForMerging?.centerTwoId.length) {
      if (centerForMerging?.centerTwoId.includes(record.id)) {
        const newList = centerForMerging?.centerTwoId.filter((item) => item !== record.id);
        const newListData = centerForMerging?.centerTwoData.filter((item) => item.id === record.id);
        setCenterForMerging({
          ...centerForMerging,
          centerTwoId: newList,
          centerTwoData: newListData,
        });
      } else {
        setCenterForMerging({
          ...centerForMerging,
          centerTwoId: [...centerForMerging?.centerTwoId, record.id],
          centerTwoData: [...centerForMerging.centerTwoData, record],
        });
      }
    } else {
      setCenterForMerging({
        ...centerForMerging,
        centerTwoId: [record.id],
        centerTwoData: [record],
      });
    }
  };

  const handlePiTwoSelected = (record) => {
    // setPiForMerging({ ...piForMerging, piTwoId: record.id, piTwoData: record })
    if (piForMerging?.piTwoId && piForMerging?.piTwoId.length) {
      if (piForMerging?.piTwoId.includes(record.id)) {
        const newList = piForMerging?.piTwoId.filter((item) => item !== record.id);
        const newListData = piForMerging?.piTwoData.filter((item) => item.id === record.id);
        setPiForMerging({ ...piForMerging, piTwoId: newList, piTwoData: newListData });
      } else {
        setPiForMerging({
          ...piForMerging,
          piTwoId: [...piForMerging.piTwoId, record.id],
          piTwoData: [...piForMerging.piTwoData, record],
        });
      }
    } else {
      setPiForMerging({ ...piForMerging, piTwoId: [record.id], piTwoData: [record] });
    }
  };

  const centerMergeMenu = (record) => {
    return (
      <Menu
        onClick={() => handleCenterMenuClick(record)}
        className="project-detail-cardmenu-dropdown"
        items={[
          {
            key: '1',
            label: 'Merge Duplicate',
            className:
              centerForMerging.isCenterSelected
              || (itm.parent_flag
                && !(
                  adminType.includes('super_admin')
                  || adminType.includes('lcor_access')
                  || adminType.includes('sam_access')
                ))
                ? 'project-details-dropdown-icon comparision-dropdown-icon disabled'
                : 'project-details-dropdown-icon comparision-dropdown-icon',
            disabled:
            (!record.parent_flag && record?.name?.startsWith('Investigators Without Primary Org')) || centerForMerging.isCenterSelected
              || (itm.parent_flag
                && !(
                  adminType.includes('super_admin')
                  || adminType.includes('lcor_access')
                  || adminType.includes('sam_access')
                )),
          },
        ]}
      />
    );
  };

  const piMergeMenu = (record) => {
    return (
      <Menu
        onClick={() => handlePiMenuClick(record)}
        className="project-detail-cardmenu-dropdown"
        items={[
          {
            key: '1',
            label: 'Merge Duplicate',
            className: piForMerging?.isPiSelected
              ? 'project-details-dropdown-icon comparision-dropdown-icon disabled'
              : 'project-details-dropdown-icon comparision-dropdown-icon',
            disabled: piForMerging?.isPiSelected,
          },
        ]}
      />
    );
  };

  const getClinicalId = (cId) => {
    if (Array.isArray(cId)) {
      const nctId = cId.filter((option) => option.startsWith('NCT'));
      if (nctId.length > 0) {
        return nctId;
      }
      return cId[0];
    }
    return cId;
  };

  const renderGraph = (record) => {
    const combineData = [];
    const label = [];
    const hispanic = record?.census_data?.hispanic;
    hispanic.forEach((r) => {
      const k = r.key;
      if (k !== 'Total') {
        const fData = record?.census_data.non_hispanic
          ?.filter((f) => f.key !== 'Total')
          .find((i) => i.key === k);
        label.push(r.key);
        combineData.push({
          key: k,
          hdata: fData.value,
          nondata: r.value,
        });
      }
    });
    return {
      labels: label,
      datasets: [
        {
          label: 'Hispanic',
          data: combineData.map((r) => r.nondata),
          backgroundColor: '#D3D800',
          stack: 'Stack 0',
        },
        {
          label: 'Non-Hispanic',
          data: combineData.map((r) => r.hdata),
          backgroundColor: '#4E565B',
          stack: 'Stack 1',
        },
      ],
    };
  };

  const onPieChartValue = () => {
    if (Object.keys(pieChartData).length) {
      const data1 = Object.keys(pieChartData)
        .filter((key) => key === tabName)
        .reduce((obj, key) => {
          obj[key] = pieChartData[key];
          return obj;
        }, {});
      return (
        <PieChart
          data={data1[tabName]}
          outerRadius={70}
          innerRadius={35}
          type="SITE"
          idName={`pi${itm.iid}`}
          flag={false}
        />
      );
    }
    return '';
  };

  const OnTierClick = (piId) => {
    setTierClicked(true);
    setSelectedPiId(piId);
  };

  const onViewMoreKOLClick = () => {
    setKOLModal(true);
  };

  const getStars = (value) => {
    if (value === 'Class 3') {
      return 1;
    }
    if (value === 'Class 2') {
      return 2;
    }
    if (value === 'Class 1') {
      return 3;
    }
    return 0;
  };
  const getDatefromTimestamp = (d) => {
    return new Date(d * 1000).toUTCString().substring(4, 16);
  };

  const [onTierClicked, setOnTierClicked] = useState(false);

  const onTierClick = () => {
    setOnTierClicked(true);
  };

  const handleSponsorSelect = (e) => {
    if (itm.id === selectedFromSearchPI.id) {
      setSelectedFromSearchPI({})
    } else setSelectedFromSearchPI(e)
  }
  const returnTemplate = () => {
    switch (type) {
      case 'SITE_Parent':
      case 'SITE': {
        return (
          <>
            <div
              className={`card ${
                centerForMerging?.centerOneId === itm.id
                || (centerForMerging?.centerTwoId !== undefined
                  && centerForMerging?.centerTwoId.includes(itm.id))
                  ? 'card-selected'
                  : null
              } ${itm.type} ${itm.parent_flag === true ? 'flag-active' : 'no-flag'} ${
                itm.is_invalid === true ? 'invalid-itm-card' : ''
              }`}
            >
              <div className="card-header">
                <div className="card-title">
                  <span className="flag-icon-img" />
                  <a
                    target="_blank"
                    href={`/search/deepdives?${stringify({
                      ...parse(location.search),
                      id: itm.id,
                      type: itm.type,
                      currentTab: itm.parent_flag ? 'parent_centers' : 'centers',
                    })}`}
                    rel="noopener noreferrer"
                  >
                    <Tooltip
                      title={(
                        <div>
                          <div>
                            Last modified by:{' '}
                            {itm.last_modified_by_user_name ? itm.last_modified_by_user_name : '-'}
                          </div>
                          <div>
                            Last modified on:{' '}
                            {itm.last_modified_on
                              ? getDatefromTimestamp(itm.last_modified_on)
                              : '-'}
                          </div>
                        </div>
                      )}
                    >
                      {itm.name}
                    </Tooltip>
                  </a>
                  {itm.is_invalid && (
                    <div className="invalid-heading" title={`Reason: ${itm.is_invalid_reason}`} />
                  )}
                  {
                  type === 'SITE_Parent' && (
                    <span
                      className={
                          itm.center_quality === 'Compliance not preventing participation'
                          || itm.center_quality === 'Approved with Warning'
                            ? 'title-head-compliance-flag compliance-flag-icon preventing'
                            : itm.center_quality === 'Compliance preventing participation'
                              || itm.center_quality === 'DNU'
                              ? 'title-head-compliance-flag compliance-flag-icon not-preventing'
                              : itm?.center_quality === 'Archived issue'
                                ? 'title-head-compliance-flag compliance-flag-icon archive-issue'
                                : 'title-head-compliance-flag'
                        }
                      title={
                          itm.center_quality === 'Compliance not preventing participation'
                            ? 'Compliance not preventing participation'
                            : itm.center_quality === 'Compliance preventing participation'
                              ? 'Compliance preventing participation'
                              : itm.center_quality === 'DNU'
                                ? 'DNU'
                                : itm.center_quality === 'Approved with Warning'
                                  ? 'Approved with Warning'
                                  : itm?.center_quality === 'Archived issue'
                                    ? 'Archived issue'
                                    : null
                        }
                    />
                  )
                    }
                  {
                  type === 'SITE' && (
                    <span
                      className={
                          itm.center_quality === 'Compliance not preventing participation'
                          || itm.center_quality === 'Approved with Warning'
                            ? 'title-head-compliance-flag compliance-flag-icon preventing'
                            : itm.center_quality === 'Compliance preventing participation'
                              || itm.center_quality === 'DNU'
                              ? 'title-head-compliance-flag compliance-flag-icon not-preventing'
                              : itm?.center_quality === 'Archived issue'
                                ? 'title-head-compliance-flag compliance-flag-icon archive-issue'
                                : 'title-head-compliance-flag'
                        }
                      title={
                          itm.center_quality === 'Compliance not preventing participation'
                            ? `Compliance not preventing participation ${itm.center_quality_inherit_flag ? '(Inherited from parent)' : ''}`
                            : itm.center_quality === 'Compliance preventing participation'
                              ? `Compliance preventing participation ${itm.center_quality_inherit_flag ? '(Inherited from parent)' : ''}`
                              : itm.center_quality === 'DNU'
                                ? `DNU ${itm.center_quality_inherit_flag ? '(Inherited from parent)' : ''}`
                                : itm.center_quality === 'Approved with Warning'
                                  ? `Approved with Warning ${itm.center_quality_inherit_flag ? '(Inherited from parent)' : ''}`
                                  : itm?.center_quality === 'Archived issue'
                                    ? `Archived issue ${itm.center_quality_inherit_flag ? '(Inherited from parent)' : ''}`
                                    : null
                        }
                    />
                  )
                    }
                  {
                      (type === 'SITE_Parent' || type === 'SITE') && (
                        <span
                          className={
                              itm.veeva_ready_flag_updated === 'Yes'
                                ? 'title-head-veeva-ready-flag veeva-ready-flag yes-flag'
                                : itm.veeva_ready_flag_updated === 'No'
                                  ? 'title-head-veeva-ready-flag veeva-ready-flag no-flag'
                                  : itm.veeva_ready_flag_updated === 'Pending review'
                                    ? 'title-head-veeva-ready-flag veeva-ready-flag pending-review-flag'
                                    : 'title-head-veeva-ready-flag'
                          }
                          title={
                            itm.veeva_ready_flag_updated === 'Yes'
                              ? 'Veeva Ready Status : Yes'
                              : itm.veeva_ready_flag_updated === 'No'
                                ? 'Veeva Ready Status : No'
                                : itm.veeva_ready_flag_updated === 'Pending review'
                                  ? 'Veeva Ready Status : Pending Review'
                                  : null
                          }
                        />
                      )
                    }

                </div>

                {!fromSearchResult ? (
                  <div className="card-meta">
                    {
                      !isViewOnlyAccess() && (
                        <div className="comments">
                          <Comment
                            type="list_site"
                            siteId={itm.id}
                            count={JSON.stringify(itm.comments)}
                          />
                        </div>
                      )
                    }
                    {
                     !isViewOnlyAccess() && (
                     <div className="fav">
                       <Bookmark
                         isBookmarked={itm.isBookmarked.flag}
                         type="centers"
                         id={itm.id}
                         bookmark_id={itm.isBookmarked.bookmark_id}
                       />
                     </div>
                     )
                    }
                    <Tooltip title="View Center/Institute">
                      <div className="source">
                        <a
                          target="_blank"
                          href={`/search/deepdives?${stringify({
                            ...parse(location.search),
                            id: itm.id,
                            type: itm.type,
                            currentTab: itm.parent_flag ? 'parent_centers' : 'centers',
                          })}`}
                          rel="noopener noreferrer"
                          aria-label={itm.parent_flag ? 'parent_centers' : 'centers'}
                        >
                          <div className="source-img" />
                        </a>
                      </div>
                    </Tooltip>
                  </div>
                ) : (
                  <div className="card-meta">
                    {
                      !isViewOnlyAccess() && (
                        <div className="comments">
                          <Comment
                            type="list_site"
                            siteId={itm.id}
                            count={JSON.stringify(itm.comments)}
                          />
                        </div>
                      )
                    }
                    {
                      !isViewOnlyAccess() && (
                        <div className="fav">
                          <Bookmark
                            isBookmarked={itm.isBookmarked.flag}
                            type="centers"
                            id={itm.id}
                            bookmark_id={itm.isBookmarked.bookmark_id}
                          />
                        </div>
                      )
                    }
                    {
                      !isViewOnlyAccess() && (
                        !centerForMerging.isCenterSelected
                          && !itm.is_invalid === true
                          && !itm.is_merge_request_submitted === true ? (
                            <Dropdown
                              getPopupContainer={(trigger) => trigger.parentNode}
                              overlay={() => centerMergeMenu(itm)}
                              trigger={['click']}
                            >
                              <Button
                                className="accordion-menu"
                                role="presentation"
                                onClick={(e) => {
                                  e.preventDefault();
                                }}
                              >
                                <div className="accordion-menu-icon" />
                              </Button>
                            </Dropdown>
                          ) : centerForMerging?.centerOneId === itm.id
                            && !itm.is_invalid === true
                            && !itm.is_merge_request_submitted === true ? (
                              <Dropdown
                                getPopupContainer={(trigger) => trigger.parentNode}
                                overlay={() => centerMergeMenu(itm)}
                                trigger={['click']}
                              >
                                <Button
                                  className="accordion-menu"
                                  role="presentation"
                                  onClick={(e) => {
                                    e.preventDefault();
                                  }}
                                >
                                  <div className="accordion-menu-icon" />
                                </Button>
                              </Dropdown>
                            ) : (
                              !itm.is_invalid === true
                            && !itm.is_merge_request_submitted === true && (
                              <Checkbox
                                onChange={() => handleCenterTwoSelected(itm)}
                                checked={
                                  centerForMerging.centerTwoId !== undefined
                                  && centerForMerging?.centerTwoId.includes(itm.id)
                                }
                              />
                              )
                            )
                      )
                    }
                    <Tooltip
                      title={
                        parse(location.search).currentTab === 'centers'
                          ? 'View Center'
                          : 'View Institute'
                      }
                    >
                      <div className="source">
                        <a
                          target="_blank"
                          href={`/search/deepdives?${stringify({
                            ...parse(location.search),
                            id: itm.id,
                            type: itm.type,
                            currentTab: itm.parent_flag ? 'parent_centers' : 'centers',
                          })}`}
                          rel="noopener noreferrer"
                          aria-label={itm.parent_flag ? 'parent_centers' : 'centers'}
                        >
                          <div className="source-img" />
                        </a>
                      </div>
                    </Tooltip>

                  </div>
                )}
              </div>
              <div className="content">
                <div className="leftside">
                  <div className="person-details">
                    <div className="contact_name">
                      <div className="title-child">Address:</div>
                      {itm.address ? (
                        <Tooltip title={(
                          <div>
                            {itm?.address?.address_line1 && `${itm?.address?.address_line1}, `}
                            {itm?.address?.address_line2 && `${itm?.address?.address_line2}, `}
                            {itm?.address?.city && `${itm?.address?.city}, `}
                            {itm?.address?.state && `${itm?.address?.state}, `}
                            {itm?.address?.country && `${itm?.address?.country}, `}
                            {itm?.address?.zip}
                          </div>
                      )}
                        >
                          <div className="content-child">
                            {itm?.address?.address_line1 && `${itm?.address?.address_line1}, `}
                            {itm?.address?.address_line2 && `${itm?.address?.address_line2}, `}
                            {itm?.address?.city && `${itm?.address?.city}, `}
                            {itm?.address?.state && `${itm?.address?.state}, `}
                            {itm?.address?.country && `${itm?.address?.country}, `}
                            {itm?.address?.zip}
                          </div>
                        </Tooltip>
                      ) : (
                        <div className="content-child no-data">No data available</div>
                      )}
                    </div>
                    <div className="emailofcontact">
                      <div className="title-child">Primary Email:</div>
                      {itm.email && itm.email.length ? (
                        <Tooltip title={itm.email.join(', ')}>
                          <div className="content-child">
                            {itm.email.join(', ')}
                            <InputWithCopy text={itm} type="email" />
                          </div>
                        </Tooltip>

                      ) : (
                        <div className="content-child no-data">No data available</div>
                      )}
                    </div>
                    <div className="mobileOfcontact">
                      <div className="title-child">Primary Phone Number:</div>
                      {itm.primary_phone && itm.primary_phone.length ? (
                        <>
                          <Tooltip title={itm.primary_phone}>
                            <div className="content-child">{itm.primary_phone}</div>
                          </Tooltip>
                        </>

                      ) : (
                        <div className="content-child no-data">No data available</div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="right-side">
                  {
                    type === 'SITE' && (
                      <div style={{ display: 'flex' }}>
                        <div> Parent Institution Name: </div>
                        {Object.keys(itm.parent_institution) && Object.keys(itm.parent_institution).length ? (
                          <a
                            target="_blank"
                            href={`/search/deepdives?${stringify({
                              ...parse(location.search),
                              id: itm.parent_institution.id,
                              currentTab: 'parent_centers',
                            })}`}
                            rel="noopener noreferrer"
                          >&nbsp;{itm.parent_institution.name}
                          </a>
                        )
                          : itm.solo_center_flag === true ? (
                            <span className="value" style={{ fontSize: '14px' }}>&nbsp;Solo Center / No Parent</span>
                          )
                            : (
                              <span className="value" style={{ fontSize: '14px', color: '#848484' }}>&nbsp;No data available</span>
                            )}
                      </div>
                    )
                  }
                  <div className="tags-data">
                    {
                      type === 'SITE' && (
                        <Tag>
                          <div className="details-abt-tags">
                            <div className="nameOfTag">{tagTitle.tier}</div>
                            <div className="sizeOftag" role="presentation" onClick={onTierClick} style={{ cursor: 'pointer' }}>{itm.tier}</div>
                          </div>
                        </Tag>
                      )
                    }
                    <Tag>
                      <div className="details-abt-tags">
                        <div className="nameOfTag">{tagTitle.alliance}</div>
                        <div className="sizeOftag">
                          {itm.alliance_type ? itm.alliance_type : '-'}
                        </div>
                      </div>
                    </Tag>
                    <Tag>
                      <div className="details-abt-tags">
                        <div className="nameOfTag">{tagTitle.compliance}</div>
                        <div className="sizeOftag">{itm.compliance ? 'Yes' : 'No'}</div>
                      </div>
                    </Tag>
                    <Tag>
                      <div className="details-abt-tags">
                        <div className="nameOfTag">{tagTitle.total_number_of_popis}</div>
                        <div className="sizeOftag">{itm.total_number_of_popis}</div>
                      </div>
                    </Tag>
                    <Tag>
                      <div className="details-abt-tags">
                        <div className="nameOfTag">{tagTitle.industry_studies}</div>
                        <div className="sizeOftag">{itm.industry_studies}</div>
                      </div>
                    </Tag>
                    <Tag>
                      <div className="details-abt-tags">
                        <div className="nameOfTag">{tagTitle.interventional_studies}</div>
                        <div className="sizeOftag">{itm.interventional_studies}</div>
                      </div>
                    </Tag>
                    <Tag>
                      <div className="details-abt-tags">
                        <div className="nameOfTag">{tagTitle.observational_studies}</div>
                        <div className="sizeOftag">{itm.observational_studies}</div>
                      </div>
                    </Tag>
                    <Tag>
                      <div className="details-abt-tags">
                        <div className="nameOfTag">{tagTitle.parexel_studies}</div>
                        <div className="sizeOftag">{itm.parexel_studies}</div>
                      </div>
                    </Tag>
                    <Tag>
                      <div className="details-abt-tags">
                        <div className="nameOfTag">{tagTitle.enrollment_subjects}</div>
                        <span className="sizeOftag">{itm.enrollment_subjects}</span>
                      </div>
                    </Tag>
                    <Tag>
                      <div className="details-abt-tags">
                        <div className="nameOfTag">{tagTitle.department}</div>
                        <span className="sizeOftag">{itm.department ? itm.department : '-'}</span>
                      </div>
                    </Tag>
                    <Tag>
                      <div className="details-abt-tags">
                        <div className="nameOfTag">{tagTitle.indications}</div>
                        <Tooltip
                          placement="bottom"
                          title={
                            itm.indications && itm.indications.length
                              ? itm.indications.join('; ')
                              : '-'
                          }
                        >
                          <span className="sizeOftag">
                            {itm.indications && itm.indications.length
                              ? itm.indications.join('; ')
                              : '-'}
                          </span>
                        </Tooltip>
                      </div>
                    </Tag>
                    <Tag>
                      <div className="details-abt-tags">
                        <div className="nameOfTag">Sources&nbsp;</div>
                        <Tooltip
                          placement="bottom"
                          title={itm.sources && itm.sources.length ? itm.sources.join(', ') : '-'}
                        >
                          <span className="sizeOftag">
                            {itm.sources && itm.sources.length ? itm.sources.join(', ') : '-'}
                          </span>
                        </Tooltip>
                      </div>
                    </Tag>
                    <Tag>
                      <div className="details-abt-tags">
                        <div className="nameOfTag">
                          {parse(location.search).currentTab === 'centers'
                            ? 'Center Type'
                            : 'Parent Institution Type'}
                        </div>
                        <Tooltip placement="bottom" title={itm.center_type ? itm.center_type : '-'}>
                          <span className="sizeOftag">
                            {itm.center_type ? itm.center_type : '-'}
                          </span>
                        </Tooltip>
                      </div>
                    </Tag>
                    {itm.pxl_center_sims_ids ? (
                      <Tag>
                        <div className="details-abt-tags">
                          <div className="nameOfTag">SIMS IDs&nbsp;</div>
                          <Tooltip
                            placement="bottom"
                            title={
                              itm.pxl_center_sims_ids && itm.pxl_center_sims_ids.length
                                ? itm.pxl_center_sims_ids.join(', ')
                                : '-'
                            }
                          >
                            <span className="sizeOftag">
                              {itm.pxl_center_sims_ids && itm.pxl_center_sims_ids.length
                                ? itm.pxl_center_sims_ids.join(', ')
                                : '-'}
                            </span>
                          </Tooltip>
                        </div>
                      </Tag>
                    ) : null}
                    {itm.pxl_center_cit_ids ? (
                      <Tag>
                        <div className="details-abt-tags">
                          <div className="nameOfTag">Citeline IDs&nbsp;</div>
                          <Tooltip
                            placement="bottom"
                            title={
                              itm.pxl_center_cit_ids && itm.pxl_center_cit_ids.length
                                ? itm.pxl_center_cit_ids.join(', ')
                                : '-'
                            }
                          >
                            <span className="sizeOftag">
                              {itm.pxl_center_cit_ids && itm.pxl_center_cit_ids.length
                                ? itm.pxl_center_cit_ids.join(', ')
                                : '-'}
                            </span>
                          </Tooltip>
                        </div>
                      </Tag>
                    ) : null}

                    {itm.landscape_id ? (
                      <Tag>
                        <div className="details-abt-tags">
                          <div className="nameOfTag">LandSCAPE ID&nbsp;</div>
                          <Tooltip
                            placement="bottom"
                            title={itm.landscape_id ? itm.landscape_id : '-'}
                          >
                            <span className="sizeOftag">
                              {itm.landscape_id ? itm.landscape_id : '-'}
                            </span>
                          </Tooltip>
                        </div>
                      </Tag>
                    ) : null}
                    <Tag>
                      <div className="details-abt-tags">
                        <div className="nameOfTag">Center Quality</div>
                        <div className="sizeOftag">{itm.center_quality}</div>
                      </div>
                    </Tag>
                    <Tag>
                      <div className="details-abt-tags">
                        <div className="nameOfTag">Veeva Ready Status</div>
                        <div className="sizeOftag">{itm.veeva_ready_flag_updated ? itm.veeva_ready_flag_updated : '-'}</div>
                      </div>
                    </Tag>
                    <Tag>
                      <div className="details-abt-tags">
                        <div className="nameOfTag">External Id</div>
                        <div className="sizeOftag">{itm?.external_id?.length ? itm?.external_id?.join(', ') : '-'}</div>
                      </div>
                    </Tag>
                    {/* { type === 'SITE' ? ( */}
                    <Tag>
                      <div className="details-abt-tags">
                        <div className="nameOfTag">USN</div>
                        <div className="sizeOftag">{itm.usn_id ? itm.usn_id : '-'}</div>
                      </div>
                    </Tag>

                    {/* ) : null} */}

                    {type === 'SITE_Parent' ? (
                      <Tag>
                        <div className="details-abt-tags">
                          <div className="nameOfTag"># of Child Centers</div>
                          <div className="sizeOftag">{itm.total_child_centers}</div>
                        </div>
                      </Tag>
                    ) : null}
                  </div>
                  <div className="reliability-quality-rating">
                    <Tooltip
                      title={itm.reliability ? `Reliability score : ${itm.reliability}` : '-'}
                    >
                      <div className="reliability rating-sec">
                        <div className="rating-sec-title">Reliability</div>

                        {itm.reliability_class && itm.reliability_class !== '' ? (
                          <StarRatings
                            rating={getStars(itm.reliability_class)}
                            starRatedColor="#8A0050"
                            numberOfStars={3}
                            starDimension="16px"
                            starSpacing="1px"
                            starEmptyColor="#D099B9"
                          />
                        ) : (
                          <div className="content-child no-data">&nbsp;No data available</div>
                        )}
                      </div>
                    </Tooltip>
                  </div>
                </div>
                {onTierClicked && (
                  <div>
                    <ScoreModal
                      onItmClicked={onTierClicked}
                      setOnItmClicked={setOnTierClicked}
                      data={itm}
                      flag="site-tier"
                    />
                  </div>
                )}
                <div>
                  {itm.census_data ? (
                    Object.keys(itm.census_data).length ? (
                      <Bar options={options} data={renderGraph(itm)} />
                    ) : (
                      <div className="no-data-inside">No data found</div>
                    )
                  ) : null}
                </div>
              </div>
            </div>
          </>
        );
      }
      case 'PI': {
        return (
          <>
            {!IsModalProps ? (
              <div
                // className={`card ${itm.type} ${piForMerging?.piOneId === itm.id || piForMerging?.piTwoId === itm.id ? 'card-selected' : null} ${itm.is_invalid === true ? 'invalid-itm-card' : ''}`}
                className={`card ${
                  piForMerging?.piOneId === itm.id
                  || (piForMerging?.piTwoId !== undefined && piForMerging?.piTwoId.includes(itm.id))
                    ? 'card-selected'
                    : null
                } ${itm.type} ${itm.parent_flag === true ? 'flag-active' : 'no-flag'} ${
                  itm.is_invalid === true ? 'invalid-itm-card' : ''
                }`}
                role="presentation"
              >
                <div className="card-header card-header-notitle card-header-investigator">
                  {!fromSearchResult ? (
                    <div className="card-meta">
                      <div className="card-meta-header">
                        {
                          !isViewOnlyAccess() && (
                            <div className="comments">
                              <Comment
                                type="list_pi"
                                piId={itm.id}
                                count={JSON.stringify(itm.comments)}
                              />
                            </div>
                          )
                        }
                        {
                           !isViewOnlyAccess() && (
                           <div className="fav">
                             <Bookmark
                               isBookmarked={itm.isBookmarked.flag}
                               type="investigators"
                               id={itm.id}
                               bookmark_id={itm.isBookmarked.bookmark_id}
                             />
                           </div>
                           )
                        }
                      </div>
                      <div className="card-meta-footer">
                        <Tooltip title="View Investigator">
                          <div className="source">
                            <a
                              target="_blank"
                              href={`/search/investigatorprofile?${stringify({
                                ...parse(location.search),
                                id: itm.id,
                                type: itm.type,
                              })}`}
                              rel="noopener noreferrer"
                              aria-label="view investigator"
                            >
                              <div className="source-img" />
                            </a>
                          </div>
                        </Tooltip>
                      </div>
                    </div>
                  ) : (
                    <div className="card-meta">
                      <div className="card-meta-header card-meta">
                        {
                          !isViewOnlyAccess() && (
                            <div className="comments">
                              <Comment
                                type="list_pi"
                                piId={itm.id}
                                count={JSON.stringify(itm.comments)}
                              />
                            </div>
                          )
                        }
                        {
                          !isViewOnlyAccess() && (
                            <div className="fav">
                              <Bookmark
                                isBookmarked={itm.isBookmarked.flag}
                                type="investigators"
                                id={itm.id}
                                bookmark_id={itm.isBookmarked.bookmark_id}
                              />
                            </div>

                          )
                        }
                        {
                           !isViewOnlyAccess() && (
                             !piForMerging?.isPiSelected
                              && !itm.is_invalid === true
                              && !itm.is_merge_request_submitted === true ? (
                                <Dropdown
                                  getPopupContainer={(trigger) => trigger.parentNode}
                                  overlay={() => piMergeMenu(itm)}
                                  trigger={['click']}
                                >
                                  <Button
                                    className="accordion-menu"
                                    role="presentation"
                                    onClick={(e) => {
                                      e.preventDefault();
                                    }}
                                  >
                                    <div className="accordion-menu-icon" />
                                  </Button>
                                </Dropdown>
                               ) : piForMerging?.piOneId === itm.id
                                && !itm.is_invalid === true
                                && !itm.is_merge_request_submitted === true ? (
                                  <Dropdown
                                    getPopupContainer={(trigger) => trigger.parentNode}
                                    overlay={() => piMergeMenu(itm)}
                                    trigger={['click']}
                                  >
                                    <Button
                                      className="accordion-menu"
                                      role="presentation"
                                      onClick={(e) => {
                                        e.preventDefault();
                                      }}
                                    >
                                      <div className="accordion-menu-icon" />
                                    </Button>
                                  </Dropdown>
                                 ) : (
                                   !itm.is_invalid === true
                                && !itm.is_merge_request_submitted === true && (
                                  <Checkbox
                                    onChange={() => handlePiTwoSelected(itm)}
                                    checked={
                                      piForMerging?.piTwoId !== undefined
                                      && piForMerging?.piTwoId.includes(itm.id)
                                    }
                                  />
                                   )
                                 )
                           )
                        }
                      </div>
                      <div className="card-meta-footer">
                        <div className="merge-btn">
                          {piForMerging?.isPiSelected && piForMerging.piTwoId === itm.id ? (
                            <Button
                              onClick={() => handleMergeClicked('investigator')}
                              className="source"
                            >
                              Merge Investigators
                            </Button>
                          ) : (
                            <Tooltip title="View Investigator">
                              <div className="source">
                                <a
                                  target="_blank"
                                  href={`/search/investigatorprofile?${stringify({
                                    ...parse(location.search),
                                    id: itm.id,
                                    type: itm.type,
                                  })}`}
                                  rel="noopener noreferrer"
                                  aria-label="View Investigator"
                                >
                                  <div className="source-img" />
                                </a>
                              </div>
                            </Tooltip>
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <div className="content">
                  <div className="card-person-img">
                    <div className="img-person no-img" />
                  </div>
                  <div className="leftside">
                    <div className="title pi-name-card">
                      <Tooltip
                        title={(
                          <div>
                            <div>
                              Last modified by:{' '}
                              {itm.last_modified_by_user_name
                                ? itm.last_modified_by_user_name
                                : '-'}
                            </div>
                            <div>
                              Last modified on:{' '}
                              {itm.last_modified_on
                                ? getDatefromTimestamp(itm.last_modified_on)
                                : '-'}
                            </div>
                          </div>
                        )}
                      >
                        <a
                          target="_blank"
                          href={`/search/investigatorprofile?${stringify({
                            ...parse(location.search),
                            id: itm.id,
                            type: itm.type,
                          })}`}
                          rel="noopener noreferrer"
                          title={itm.name}
                        >
                          {itm.name}
                        </a>
                      </Tooltip>
                      <span
                        className={
                        itm.compliance === 'Compliance not preventing participation'
                        || itm.compliance === 'Approved with Warning'
                          ? 'title-head-compliance-flag compliance-flag-icon preventing'
                          : itm.compliance === 'Compliance preventing participation'
                            || itm.compliance === 'DNU'
                            ? 'title-head-compliance-flag compliance-flag-icon not-preventing'
                            : itm?.compliance === 'Archived issue'
                              ? 'title-head-compliance-flag compliance-flag-icon archive-issue'
                              : 'title-head-compliance-flag'
                      }
                        title={
                        itm.compliance === 'Compliance not preventing participation'
                          ? 'Compliance not preventing participation'
                          : itm.compliance === 'Compliance preventing participation'
                            ? 'Compliance preventing participation'
                            : itm.compliance === 'DNU'
                              ? 'DNU'
                              : itm.compliance === 'Approved with Warning'
                                ? 'Approved with Warning'
                                : itm?.compliance === 'Archived issue'
                                  ? 'Archived Issue'
                                  : null
                      }
                      />

                      <span
                        className={
                          itm.veeva_ready_flag_updated === 'Yes'
                            ? 'title-head-veeva-ready-flag veeva-ready-flag yes-flag'
                            : itm.veeva_ready_flag_updated === 'No'
                              ? 'title-head-veeva-ready-flag veeva-ready-flag no-flag'
                              : itm.veeva_ready_flag_updated === 'Pending review'
                                ? 'title-head-veeva-ready-flag veeva-ready-flag pending-review-flag'
                                : 'title-head-veeva-ready-flag'
                      }
                        title={
                        itm.veeva_ready_flag_updated === 'Yes'
                          ? 'Veeva Ready Status : Yes'
                          : itm.veeva_ready_flag_updated === 'No'
                            ? 'Veeva Ready Status : No '
                            : itm.veeva_ready_flag_updated === 'Pending review'
                              ? 'Veeva Ready Status : Pending Review'
                              : null
                      }
                      />

                      &nbsp;
                      {itm.is_invalid && (
                        <div
                          className="invalid-heading"
                          title={`Reason: ${itm.is_invalid_reason}`}
                        />
                      )}
                    </div>
                    <div className="info-ratings-sec-parent">
                      <div className="info-ratings-sec info-ratings-sec-left">
                        <div className="info-ratings-values">
                          <div className="info-ratings-values-title">Industry studies:</div>
                          <div className="info-ratings-values-data">{itm.industry_studies}</div>
                        </div>
                        <div className="info-ratings-values">
                          <div className="info-ratings-values-title">Parexel studies:</div>
                          <div className="info-ratings-values-data">{itm.parexel_studies}</div>
                        </div>
                        <div className="info-ratings-values">
                          <div className="info-ratings-values-title">Alliance type:</div>
                          {itm.alliance_type ? (
                            <div className="normal-text">{itm.alliance_type}</div>
                          ) : (
                            <div>-</div>
                          )}
                        </div>
                        <div className="info-ratings-values">
                          <div className="info-ratings-values-title">Alliance member:</div>
                          <div
                            className="info-ratings-values-data"
                            title={itm.alliance_member ? itm.alliance_member : '-'}
                          >
                            {itm.alliance_member ? itm.alliance_member : '-'}
                          </div>
                        </div>

                        <div className="info-ratings-values">
                          <div className="info-ratings-values-title">Tier:</div>
                          <div
                            className="info-ratings-values-data cursor-click"
                            role="presentation"
                            onClick={() => OnTierClick(itm.id)}
                          >
                            {itm.tier}
                          </div>
                          {tierClicked && itm.id === selectedPiId && (
                            <div>
                              <ScoreModal
                                onItmClicked={tierClicked}
                                setOnItmClicked={setTierClicked}
                                data={itm}
                                piId={itm.id}
                                flag="pi-tier"
                              />
                            </div>
                          )}
                        </div>
                        <div className="info-ratings-values">
                          <div className="info-ratings-values-title">External Id:</div>
                          {itm?.external_id?.length ? (
                            <div className="Alliance member:-data plain-text no-ellipsis">
                              {itm?.external_id?.join(', ')}
                            </div>
                          ) : (
                            <div className="dash-nodata">-</div>
                          )}
                        </div>
                      </div>
                      <div className="info-ratings-sec info-ratings-sec-right">
                        <div className="info-ratings-values">
                          <div className="info-ratings-values-title">Enrolled subjects:</div>
                          {itm.enrollment_subjects && itm.enrollment_subjects !== '' ? (
                            <div className="info-ratings-values-data">
                              {itm.enrollment_subjects}
                            </div>
                          ) : (
                            <div className="info-ratings-values-data">0</div>
                          )}
                        </div>
                        <div className="info-ratings-values">
                          <div className="info-ratings-values-title">Reliability:</div>
                          <Tooltip
                            title={itm.reliability ? `Reliability score : ${itm.reliability}` : '-'}
                          >
                            <div className="reliability-quality-rating">
                              <div className="reliability rating-sec">
                                {itm.reliability_class && itm.reliability_class !== '' ? (
                                  <StarRatings
                                    rating={getStars(itm.reliability_class)}
                                    starRatedColor="#8A0050"
                                    numberOfStars={3}
                                    starDimension="16px"
                                    starSpacing="1px"
                                    starEmptyColor="#D099B9"
                                  />
                                ) : (
                                  <div className="content-child no-data">
                                    &nbsp;No data available
                                  </div>
                                )}
                              </div>
                            </div>
                          </Tooltip>
                        </div>
                        <div className="info-ratings-values">
                          <div className="info-ratings-values-title">Quality:</div>
                          <div className="reliability-quality-rating">
                            <div className="reliability rating-sec">
                              {itm.quality_rating !== '' ? (
                                <StarRatings
                                  rating={itm.quality_rating}
                                  starRatedColor="#8A0050"
                                  numberOfStars={3}
                                  starDimension="16px"
                                  starSpacing="1px"
                                  starEmptyColor="#D099B9"
                                />
                              ) : (
                                <div className="dash-nodata">-</div>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="info-ratings-values">
                          <div className="info-ratings-values-title">Last trial start date:</div>
                          {itm.last_trial_start_date ? (
                            <div className="info-ratings-values-data plain-text no-ellipsis">
                              {itm.last_trial_start_date}
                            </div>
                          ) : (
                            <div className="dash-nodata">-</div>
                          )}
                        </div>
                        <div className="info-ratings-values">
                          <div className="info-ratings-values-title">LandSCAPE ID:</div>
                          {itm.landscape_id ? (
                            <div className="Alliance member:-data plain-text no-ellipsis">
                              {itm.landscape_id}
                            </div>
                          ) : (
                            <div className="dash-nodata">-</div>
                          )}
                        </div>
                        <div className="info-ratings-values">
                          <div className="info-ratings-values-title">Veeva Ready Status:</div>
                          {itm.veeva_ready_flag_updated ? (
                            <div className="Alliance member:-data plain-text no-ellipsis">
                              {itm.veeva_ready_flag_updated}
                            </div>
                          ) : (
                            <div className="dash-nodata">-</div>
                          )}
                        </div>
                      </div>
                      <div className="info-ratings-sec info-ratings-sec-right">
                        <div className="info-ratings-values">
                          <div className="info-ratings-values-title">GCP Compliance/CDD Status:&nbsp;</div>
                          <div className="normal-text">
                            {itm.compliance}
                          </div>
                        </div>
                        <div className="info-ratings-values">
                          <div className="info-ratings-values-title">Sources:&nbsp;</div>
                          {itm.sources && itm.sources.length
                            ? (
                              <div className="normal-text">{itm.sources.join(', ')}</div>
                            )
                            : (
                              <div>-</div>
                            )}
                        </div>
                        <div className="info-ratings-values">
                          <div className="info-ratings-values-title">NPI:&nbsp;</div>
                          {itm.npi ? <div>{itm.npi}</div> : <div>-</div>}
                        </div>
                        <div className="info-ratings-values">
                          <div className="info-ratings-values-title">SIMS IDs:&nbsp;</div>
                          {itm.pxl_author_sims_ids && itm.pxl_author_sims_ids.length ? (
                            <div className="info-ratings-values-data">
                              {itm.pxl_author_sims_ids.join(', ')}
                            </div>
                          ) : (
                            <div className="info-ratings-values-data">-</div>
                          )}
                        </div>
                        <div className="info-ratings-values">
                          <div className="info-ratings-values-title">Citeline IDs:&nbsp;</div>
                          {itm.pxl_author_cit_ids && itm.pxl_author_cit_ids.length ? (
                            <div className="info-ratings-values-data">
                              {itm.pxl_author_cit_ids.join(', ')}
                            </div>
                          ) : (
                            <div className="info-ratings-values-data">-</div>
                          )}
                        </div>
                        {itm.sources.indexOf('CLS') !== -1 ? (
                          <>
                            <div className="info-ratings-values info-ratings-values-more">
                              <div
                                className="info-ratings-values-title"
                                title="KOL scores are derived based on research/information authored by the investigator like publications, clinical trials, congress articles, patents, grants, guidelines etc. Each document is scored considering several factors like type of document, recency of the document, role of the investigator in a given document, source where the document is published etc. Scores for each individual document is aggregated and weighted by data class, to calculate the raw KOL score. The raw KOL score for the investigators is then numerically scaled between 0 to 100 considering rest of the investigators in LandSCAPE."
                              >
                                KOL Score:&nbsp;
                              </div>
                              <div className="info-ratings-values info-ratings-values-more-align">
                                {Math.round(itm?.cls_kol_score)} % &nbsp;
                                ({itm?.cls_kol_score_class})
                                <Button onClick={onViewMoreKOLClick}>View more</Button>
                              </div>
                            </div>
                            <div>
                              {openKOLModal && (
                                <Modal
                                  className="kol-content-modal"
                                  title={(
                                    <div>
                                      <div className="kol-head-title">{itm.name}</div>
                                      <div className="kol-head-subtitle-heading">
                                        <div className="kol-head-subtitle">
                                          {itm.affiliated_site}
                                        </div>
                                        <div className="kol-head-overall-score">
                                          Overall Score:&nbsp;
                                          {itm?.kol_score.length
                                            ? itm?.kol_score?.map(
                                              (itm2) => itm2.therapeutic_area === tabName && (
                                              <div className="kol-head-overall-value">
                                                {' '}
                                                {itm2.overall_score.toFixed(3)}
                                              </div>
                                              ),
                                            )
                                            : '-'}
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                  visible={openKOLModal}
                                  onCancel={() => setKOLModal(false)}
                                  destroyOnClose
                                  footer={null}
                                  style={{ minHeight: '500px' }}
                                >
                                  {itm?.kol_score?.length ? (
                                    <>
                                      <div className="tab-header">
                                        {itm?.kol_score?.length
                                          ? itm?.kol_score?.map((itm1) => {
                                            return (
                                              <Button
                                                onClick={() => setTabName(itm1.therapeutic_area)}
                                                className={
                                                    itm1.therapeutic_area === tabName
                                                      ? 'active'
                                                      : ''
                                                  }
                                              >
                                                {itm1.therapeutic_area}
                                              </Button>
                                            );
                                          })
                                          : ''}
                                      </div>
                                      <div className="kol-content">
                                        <div className="kol-content-left">
                                          <div className="kol-content-chart">
                                            {onPieChartValue()}
                                          </div>
                                        </div>
                                        <div className="kol-content-right">
                                          <div className="kol-content-header">
                                            <div className="kol-content-header-th"> Resource </div>
                                            <div className="kol-content-header-th"> Count </div>
                                            <div className="kol-content-header-th"> Score </div>
                                          </div>
                                          <div className="kol-content-body">
                                            {itm?.kol_score?.length
                                              ? itm?.kol_score?.map(
                                                (itm1) => itm1.therapeutic_area === tabName && (
                                                <>
                                                  <div className="kol-content-header-tr">
                                                    <div className="kol-content-header-td">
                                                      <div>Publications</div>
                                                    </div>
                                                    <div className="kol-content-header-td">
                                                      <div>{itm1.pub_count}</div>
                                                    </div>
                                                    <div className="kol-content-header-td">
                                                      <div>{itm1.pub_score.toFixed(3)}</div>
                                                    </div>
                                                  </div>
                                                  <div className="kol-content-header-tr">
                                                    <div className="kol-content-header-td">
                                                      <div>Clinical Trials</div>
                                                    </div>
                                                    <div className="kol-content-header-td">
                                                      <div>{itm1.ct_count}</div>
                                                    </div>
                                                    <div className="kol-content-header-td">
                                                      <div>{itm1.ct_score.toFixed(3)}</div>
                                                    </div>
                                                  </div>
                                                  <div className="kol-content-header-tr">
                                                    <div className="kol-content-header-td">
                                                      <div>Congress</div>
                                                    </div>
                                                    <div className="kol-content-header-td">
                                                      <div>{itm1.cong_count}</div>
                                                    </div>
                                                    <div className="kol-content-header-td">
                                                      <div>{itm1.cong_score.toFixed(3)}</div>
                                                    </div>
                                                  </div>
                                                  <div className="kol-content-header-tr">
                                                    <div className="kol-content-header-td">
                                                      <div>Grants</div>
                                                    </div>
                                                    <div className="kol-content-header-td">
                                                      <div>{itm1.gl_count}</div>
                                                    </div>
                                                    <div className="kol-content-header-td">
                                                      <div>{itm1.gl_score.toFixed(3)}</div>
                                                    </div>
                                                  </div>
                                                  <div className="kol-content-header-tr">
                                                    <div className="kol-content-header-td">
                                                      <div>Patents</div>
                                                    </div>
                                                    <div className="kol-content-header-td">
                                                      <div>{itm1.pat_count}</div>
                                                    </div>
                                                    <div className="kol-content-header-td">
                                                      <div>{itm1.pat_score.toFixed(3)}</div>
                                                    </div>
                                                  </div>
                                                </>
                                                ),
                                              )
                                              : ''}
                                          </div>
                                        </div>
                                      </div>
                                    </>
                                  ) : (
                                    <div className="no-data-msg"> No data available</div>
                                  )}
                                </Modal>
                              )}
                            </div>
                          </>
                        ) : null}
                        <div className="info-ratings-values info-ratings-values-more">
                          <div className="info-ratings-values">
                            <div className="info-ratings-values-title">Emerging KOL Class:</div>
                            {itm.emerging_data.flag ? (
                              <div className="info-ratings-values-data">
                                {itm.emerging_data.flag === 'High' ? 'Rising'
                                  : itm.emerging_data.flag === 'Medium' ? 'Stable'
                                    : itm.emerging_data.flag === 'Low' ? 'Falling' : ''}
                                <Button onClick={() => setEmergingDataModal(true)}>View more</Button>
                              </div>
                            ) : (
                              <div className="info-ratings-values-data">-</div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="rightside">
                    <div className="contact-details">
                      <div className="email">
                        <span className="title">Primary Email:</span>
                        {itm?.primary_email && itm?.primary_email?.length ? (
                          <>
                            <Tooltip
                              className="copy-w-email"
                              placement="bottom"
                              title={itm?.primary_email}
                            >
                              <span className="value">{itm?.primary_email}</span>
                              <InputWithCopy text={itm} type="email" />
                            </Tooltip>
                          </>
                        ) : (
                          <span className="value" style={{ fontSize: '14px' }}>
                            No data available
                          </span>
                        )}
                        <span className="title">Survey Email:</span>
                        {itm.survey_email && itm.survey_email.length ? (
                          <Tooltip
                            placement="bottom"
                            className="copy-w-email"
                            title={itm.survey_email.join(', ')}
                          >
                            <span className="value">{itm.survey_email.join(', ')}</span>
                            <InputWithCopy text={itm} type="survey_email" />
                          </Tooltip>
                        ) : (
                          <span className="value" style={{ fontSize: '14px' }}>
                            No data available
                          </span>
                        )}
                      </div>
                      <div className="phone">
                        <span className="title">Primary Phone Number:</span>
                        {itm.primary_phone ? (
                          <Tooltip placement="bottom" title={itm.primary_phone}>
                            <span className="value">{itm.primary_phone}</span>
                          </Tooltip>
                        ) : (
                          <span className="value" style={{ fontSize: '14px' }}>
                            No data available
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="chart-sec">
                      <div className="chart">
                        {showPieChart(itm?.indication_pie_chart) ? (
                          <BarChart
                            data={itm?.indication_pie_chart.slice(0, 10)}
                            width={180}
                            height={120}
                            top={20}
                            bottom={5}
                            left={40}
                            right={20}
                            studies="indication"
                            checkDataset="investigators"
                            hideLabels
                          />
                        ) : (
                          <span className="no-data-chart">No data available</span>
                        )}
                      </div>
                      <div className="chart-data">
                        <div className="chart-content">
                          <span className="chart-title">Ongoing Studies:</span>
                          <span className="chart-value">{itm.ongoing_studies}</span>
                        </div>
                        <div className="chart-content">
                          <div className="primary-org">
                            <span className="title chart-title">
                              Parent Institution Name:&nbsp;
                            </span>
                            {Object.keys(itm.parent_institution)
                            && Object.keys(itm.parent_institution).length ? (
                              <a
                                target="_blank"
                                href={`/search/deepdives?${stringify({
                                  ...parse(location.search),
                                  id: itm.parent_institution.id,
                                  currentTab: 'parent_centers',
                                })}`}
                                rel="noopener noreferrer"
                                title={itm.parent_institution.name}
                              >
                                {itm.parent_institution.name}
                              </a>
                              ) : itm?.primary_organization?.solo_center_flag === true ? (
                                <span className="value" style={{ fontSize: '14px' }}>Solo Center / No Parent</span>
                              )
                                : (
                                  <span className="value" style={{ fontSize: '14px' }}>No data available</span>
                                )}
                          </div>
                        </div>
                        <div className="chart-content">
                          <div className="primary-org">
                            <span className="title chart-title">Primary Center:&nbsp;</span>
                            {itm.affiliated_site ? (
                              <a
                                target="_blank"
                                href={`/search/deepdives?${stringify({
                                  ...parse(location.search),
                                  currentTab: 'centers',
                                  id: itm.affiliated_site_id,
                                  type: 'SITE',
                                  query: itm.affiliated_site,
                                })}`}
                                rel="noopener noreferrer"
                                className="chart-value"
                                title={itm.affiliated_site}
                              >
                                {itm.affiliated_site}
                              </a>
                            ) : (
                              <span className="value" style={{ fontSize: '14px' }}>
                                No data available
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="chart-content">
                          <span className="chart-title">Primary Center Address:</span>
                          {itm.primary_organization && itm.primary_organization.city ? (
                            <Tooltip title={(
                              <div>
                                {itm?.primary_organization?.address_line1
                                && `${itm.primary_organization.address_line1}, `}
                                {itm.primary_organization.address_line2
                                && `${itm.primary_organization.address_line2}, `}
                                {itm.primary_organization.city
                                && `${itm.primary_organization.city}, `}
                                {itm.primary_organization.state
                                && `${itm.primary_organization.state}, `}
                                {itm.primary_organization.country
                                && `${itm.primary_organization.country}, `}
                                {itm.primary_organization.zip}
                              </div>
                            )}
                            >
                              <div className="chart-value">
                                {itm?.primary_organization?.address_line1
                                && `${itm.primary_organization.address_line1}, `}
                                {itm.primary_organization.address_line2
                                && `${itm.primary_organization.address_line2}, `}
                                {itm.primary_organization.city
                                && `${itm.primary_organization.city}, `}
                                {itm.primary_organization.state
                                && `${itm.primary_organization.state}, `}
                                {itm.primary_organization.country
                                && `${itm.primary_organization.country}, `}
                                {itm.primary_organization.zip}
                              </div>
                            </Tooltip>
                          ) : (
                            <div className="chart-value no-data">No data available</div>
                          )}
                        </div>

                        <div className="chart-content">
                          <span className="chart-title">Specialties:</span>
                          <Tooltip
                            placement="bottom"
                            title={
                              itm.specialties && itm.specialties.length
                                ? itm.specialties.join('; ')
                                : '-'
                            }
                          >
                            <span className="chart-value">
                              <ReadMore type="text" limit={100}>
                                {itm.specialties && itm.specialties.length
                                  ? itm.specialties.join('; ')
                                  : '-'}
                              </ReadMore>
                            </span>
                          </Tooltip>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className={`card ${itm.type}`} role="presentation">
                <div className="card-header card-header-notitle card-header-investigator">
                  <div className="card-meta">
                    <div className="card-meta-footer">
                      <Tooltip title="View Investigator">
                        <div className="source">
                          <a
                            target="_blank"
                            href={`/search/investigatorprofile?${stringify({
                              ...parse(location.search),
                              id: itm.id,
                              type: itm.type,
                            })}`}
                            rel="noopener noreferrer"
                            aria-label="View Investigator"
                          >
                            <div className="source-img" />
                          </a>
                        </div>
                      </Tooltip>
                    </div>
                  </div>
                </div>
                {!fromUploadSponsor && (
                  <Checkbox onChange={(e) => onChange(e)} checked={checkboxVal === index} />
                )}
                {fromUploadSponsor && (
                  <Checkbox
                    onChange={() => handleSponsorSelect(itm)}
                    checked={itm.id === selectedFromSearchPI.id}
                  />
                )}
                <div className="content">
                  <div className="card-person-img">
                    <div className="img-person no-img" />
                  </div>
                  <div className="leftside">
                    <div className="title">{itm.name}</div>
                    <div className="info-ratings-sec-parent">
                      <div className="info-ratings-sec info-ratings-sec-left">
                        <div className="info-ratings-values">
                          <div className="info-ratings-values-title">Industry studies:</div>
                          <div className="info-ratings-values-data">{itm.industry_studies}</div>
                        </div>
                        <div className="info-ratings-values">
                          <div className="info-ratings-values-title">Parexel studies:</div>
                          <div className="info-ratings-values-data">{itm.parexel_studies}</div>
                        </div>
                        <div className="info-ratings-values">
                          <div className="info-ratings-values-title">Tier:</div>
                          <div className="info-ratings-values-data">{itm.tier}</div>
                        </div>
                      </div>
                      <div className="info-ratings-sec info-ratings-sec-right">
                        <div className="info-ratings-values">
                          <div className="info-ratings-values-title">Enrolled subjects:</div>
                          {itm?.enrollment_subjects ? (
                            <div className="info-ratings-values-data">
                              {itm.enrollment_subjects}
                            </div>
                          ) : (
                            <div className="info-ratings-values-data">0</div>
                          )}
                        </div>
                        <div className="info-ratings-values">
                          <div className="info-ratings-values-title">Recruitment:</div>
                          <div className="reliability-quality-rating">
                            <div className="reliability rating-sec">
                              {itm.recruitment !== '' ? (
                                <StarRatings
                                  rating={parseInt(itm.recruitment, 10)}
                                  starRatedColor="#8A0050"
                                  numberOfStars={3}
                                  starDimension="16px"
                                  starSpacing="1px"
                                  starEmptyColor="#D099B9"
                                />
                              ) : (
                                <div className="dash-nodata">-</div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="rightside">
                    <div className="contact-details">
                      <div className="email">
                        <span className="title">Email:</span>
                        {itm.email && itm.email.length ? (
                          <Tooltip
                            placement="bottom"
                            className="copy-w-email"
                            title={itm.email.join(', ')}
                          >
                            <span className="value">{itm.email.join(', ')}</span>
                            <InputWithCopy text={itm} type="email" />
                          </Tooltip>
                        ) : (
                          <span className="value" style={{ fontSize: '14px' }}>
                            No data available
                          </span>
                        )}
                      </div>
                      <div className="phone">
                        <span className="title">Phone:</span>
                        {itm.phone_number ? (
                          <Tooltip placement="bottom" title={itm.phone_number.join(', ')}>
                            <span className="value">{itm.phone_number.join(', ')}</span>
                          </Tooltip>
                        ) : (
                          <span className="value" style={{ fontSize: '14px' }}>
                            No data available
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="chart-sec">
                      <div className="chart">
                        {showPieChart(itm?.indication_pie_chart) ? (
                          <BarChart
                            data={itm?.indication_pie_chart.slice(0, 10)}
                            width={160}
                            height={120}
                            top={20}
                            bottom={20}
                            left={40}
                            right={20}
                            studies="indication"
                            checkDataset="investigators"
                            hideLabels
                          />
                        ) : (
                          <span>No data available</span>
                        )}
                      </div>
                      <div className="chart-data">
                        <div className="chart-content">
                          <span className="chart-title">Ongoing Studies:</span>
                          <span className="chart-value">{itm.ongoing_studies}</span>
                        </div>
                        <div className="chart-content">
                          <span className="chart-title">Primary Center:</span>
                          <span className="chart-value" title={itm.affiliated_site}>
                            <ReadMore type="text" limit={100}>
                              {itm.affiliated_site || '-'}
                            </ReadMore>
                          </span>
                        </div>
                        <div className="chart-content">
                          <span className="chart-title"># Indications:</span>
                          <span className="chart-value">
                            {itm.indications && itm.indications.length ? itm.indications.length : 0}
                          </span>
                        </div>

                        <div className="chart-content">
                          <span className="chart-title">Indications:</span>
                          <Tooltip
                            placement="bottom"
                            title={
                              itm.indications && itm.indications.length
                                ? itm.indications.join('; ')
                                : ''
                            }
                          >
                            <span className="chart-value">
                              <ReadMore type="text" limit={100}>
                                {itm.indications && itm.indications.length
                                  ? itm.indications.join('; ')
                                  : ''}
                              </ReadMore>
                            </span>
                          </Tooltip>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </>
        );
      }

      case 'Project': {
        return (
          <>
            <div className={`card ${itm.type}`}>
              <div className="card-header">
                <div className="card-meta">
                  <div className="card-meta-header card-meta">
                    <div className="comments">
                      <Comment
                        type="projects"
                        projectId={itm.id}
                        count={JSON.stringify(itm.comments)}
                      />
                    </div>
                    <div className="fav">
                      <Bookmark
                        isBookmarked={itm.isBookmarked.flag}
                        type="projects"
                        id={itm.project_id}
                        bookmark_id={itm.isBookmarked.bookmark_id}
                      />
                    </div>
                  </div>
                  <div className="card-meta-footer">
                    <Tooltip title="View Project">
                      <div className="source">
                        <div
                          className="source-img"
                          onClick={() => handleClick(itm)}
                          role="presentation"
                        />
                      </div>
                    </Tooltip>
                  </div>
                </div>
              </div>
              <div className="content">
                <div className="leftside">
                  <div className="person-details">
                    <div className="contact_name">
                      <div className="title-child">Project Name</div>
                      {
                          itm.name ? (
                            <a
                              className="content-child"
                              onClick={() => handleClick(itm)}
                              role="presentation"
                              title={itm.name}
                            >
                              {itm.name}
                            </a>
                          ) : (
                            <div className="content-child no-data">No data available</div>
                          )
                        }
                    </div>
                    <div className="created_by">
                      <div className="title-child">Created by:</div>
                      {itm.user_name ? (
                        <div className="content-child">{itm.user_name}</div>
                      ) : (
                        <div className="content-child no-data">No data available</div>
                      )}
                    </div>
                    <div className="emailofcontact">
                      <div className="title-child">Created on:</div>
                      {itm.created_on ? (
                        <div className="content-child">{getFormattedDate(itm.created_on)}</div>
                      ) : (
                        <div className="content-child no-data">No data available</div>
                      )}
                    </div>
                    <div className="mobileOfcontact">
                      <div className="title-child">Modified on:</div>
                      {itm.modification_activity && itm.modification_activity.last_modified_on ? (
                        <div className="content-child">
                          {getFormattedDate(itm.modification_activity.last_modified_on)}
                        </div>
                      ) : (
                        <div className="content-child no-data">No data available</div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="right-side">
                  <div className="product-desc-values">
                    <div className="product-desc-values-title">Project Description:</div>
                    <div className="product-desc-values-data">{itm.description}</div>
                  </div>
                  <div className="tags-data">
                    <Tag>
                      <div className="details-abt-tags">
                        <div className="nameOfTag">Indications</div>
                        <Tooltip
                          placement="bottom"
                          title={
                            itm.indications && itm.indications.length
                              ? itm.indications.join('; ')
                              : ''
                          }
                        >
                          <span className="sizeOftag">
                            {itm.indications && itm.indications.length
                              ? itm.indications.join('; ')
                              : ''}
                          </span>
                        </Tooltip>
                      </div>
                    </Tag>
                    <Tag>
                      <div className="details-abt-tags">
                        <div className="nameOfTag">Sponsors</div>
                        <Tooltip
                          placement="bottom"
                          title={
                            itm.sponsors && itm.sponsors.length
                              ? getConcatenatedString(itm.sponsors, 'name')
                              : ''
                          }
                        >
                          <span className="sizeOftag">
                            {itm.sponsors && itm.sponsors.length
                              ? getConcatenatedString(itm.sponsors, 'name')
                              : ''}
                          </span>
                        </Tooltip>
                      </div>
                    </Tag>
                  </div>
                </div>
              </div>
            </div>
          </>
        );
      }

      case 'analysis': {
        return (
          <>
            <div className={`card analysis ${itm.type}`}>
              <div className="card-header">
                <div className="card-meta">
                  <div className="card-meta-header card-meta">
                    <div className="comments">
                      <Comment
                        type="analysis"
                        countType="queries"
                        projectId={itm.project_id}
                        analysisId={itm.analysis_id}
                        count={JSON.stringify(itm.comments)}
                      />
                    </div>
                    <div className="fav">
                      <Bookmark
                        isBookmarked={itm.isBookmarked.flag}
                        type="analysis"
                        id={itm.analysis_id}
                        bookmark_id={itm.isBookmarked.bookmark_id}
                      />
                    </div>
                  </div>
                  <div className="card-meta-footer">
                    <Tooltip title="View Investigator">
                      <div className="source">
                        <div
                          className="source-img"
                          onClick={() => handleClick(itm)}
                          role="presentation"
                        />
                      </div>
                    </Tooltip>
                  </div>
                </div>
              </div>
              <div className="content">
                <div className="leftside">
                  <div className="person-details">
                    <div className="contact_name">
                      {/* <div className="title-child">Analysis Name</div> */}
                      {itm.name ? (
                        <div className="content-child">{itm.name}</div>
                      ) : (
                        <div className="content-child no-data">No data available</div>
                      )}
                    </div>
                    <div className="created_by">
                      <div className="title-child">Owner:</div>
                      {itm.user_name ? (
                        <div className="content-child">{itm.user_name}</div>
                      ) : (
                        <div className="content-child no-data">No data available</div>
                      )}
                    </div>
                    <div className="emailofcontact">
                      <div className="title-child">Created on:</div>
                      {itm.created_on ? (
                        <div className="content-child">{getFormattedDate(itm.created_on)}</div>
                      ) : (
                        <div className="content-child no-data">No data available</div>
                      )}
                    </div>
                    <div className="mobileOfcontact">
                      <div className="title-child">Modified on:</div>
                      {itm.modification_activity && itm.modification_activity.last_modified_on ? (
                        <div className="content-child">
                          {getFormattedDate(itm.modification_activity.last_modified_on)}
                        </div>
                      ) : (
                        <div className="content-child no-data">No data available</div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="right-side">
                  <div className="product-desc-values product-desc-values-project">
                    <div className="product-desc-values-title">Project:</div>
                    <div className="product-desc-values-data">{itm.project_name}</div>
                  </div>
                  <div className="product-desc-values">
                    {/* <div className="product-desc-values-title">Analysis Description:</div> */}
                    <div className="product-desc-values-data">{itm.description}</div>
                  </div>
                </div>
              </div>
            </div>
          </>
        );
      }

      case 'Network': {
        return (
          <a
            target="_blank"
            href={`/search/networkdetails?${stringify({
              ...parse(location.search),
              id: itm.id,
              type: itm.type,
            })}`}
            rel="noopener noreferrer"
          >
            <Card className={`card network-tab ${itm.type}`}>
              <div className="title-date-time">
                <div className="network-card-itm-title" title={itm.name}>
                  {itm.name}
                </div>
                <div className="network-card-itm-date-time">{getFormattedDate(itm.created_on)}</div>
              </div>
              <div className="network-card-itm-desc">{itm.description}</div>
              <div className="network-card-itm-indication">
                <div className="network-card-itm-indication-title">Owner name</div>
                <div className="network-card-itm-indication-data">
                  {' '}
                  {itm.network_owner_names.length !== 0 ? itm.network_owner_names.join(',') : '-'}
                </div>
              </div>
              <div className="network-card-itm-indication">
                <div className="network-card-itm-indication-title">Indications</div>
                <div className="network-card-itm-indication-data">
                  {' '}
                  {itm.indications.length !== 0 ? itm.indications.join(';') : '-'}
                </div>
              </div>
              <div className="network-card-itm-therapy">
                <div className="network-card-itm-therapy-title">Therapy area</div>
                <div
                  className="network-card-itm-therapy-data"
                  style={{ overflowWrap: 'break-word' }}
                >
                  {itm.therapy_area.length !== 0 ? itm.therapy_area.join(';') : '-'}
                </div>
              </div>
              <div className="network-card-itm-count-section">
                <div className="network-card-itm-count">
                  <Tooltip title="# of investigators">
                    <div className="network-card-itm-count-title" />
                  </Tooltip>
                  <div className="network-card-itm-count-data">
                    {itm?.principal_investigators_count}
                  </div>
                </div>
                <div className="network-card-itm-count-center">
                  <Tooltip title="# of Centers">
                    <div className="network-card-itm-count-title-center" />
                  </Tooltip>
                  <div className="network-card-itm-count-data">{itm?.center_count}</div>
                </div>
              </div>
            </Card>
          </a>
        );
      }
      case 'study': {
        return (
          <Card className={`card network-tab ${itm.type}`}>
            <div className="card-header card-header-notitle card-header-investigator">
              <div className="card-meta">
                <div className="card-meta-footer">
                  <Tooltip title="View Study/Clinical Trial">
                    <div className="source">
                      <a
                        target="_blank"
                        href={`/search/ctdetails?${stringify({
                          ...parse(location.search),
                          id: itm.id,
                          type: itm.type,
                          cid: getClinicalId(itm.clinical_ids),
                        })}`}
                        rel="noopener noreferrer"
                        className="card-ct-link"
                        aria-label="View Study/Clinical Trial"
                      >
                        <div className="source-img" />
                      </a>
                    </div>
                  </Tooltip>
                </div>
              </div>
            </div>

            <div className="trial-tab-data">
              <div className="ct-title">
                <a
                  target="_blank"
                  href={`/search/ctdetails?${stringify({
                    ...parse(location.search),
                    id: itm.id,
                    type: itm.type,
                    cid: itm.clinical_ids,
                  })}`}
                  rel="noopener noreferrer"
                  className="card-ct-link"
                >
                  {itm.title}
                </a>
              </div>
              <div className="status-date-content">
                <div className="ct-startdate trial-list-row">
                  <div className="title-trial-in">Start date :</div>
                  <div className="desc-trial-in">{itm.date}</div>
                </div>
                <div className="ct-studystatus trial-list-row">
                  <div className="title-trial-in">Study status :</div>
                  <div className="desc-trial-in">{itm.trial_status}</div>
                </div>
                <div className="ct-phase trial-list-row">
                  <div className="title-trial-in">Phase :</div>
                  <div className="desc-trial-in">{itm.phase}</div>
                </div>
                <div className="ct-source trial-list-row">
                  <div className="title-trial-in">Source :</div>
                  <div className="desc-trial-in">{itm.sources.join(', ')}</div>
                </div>
                <div className="ct-sourceid trial-list-row">
                  <div className="title-trial-in">Source ID :</div>
                  {itm.source_study_no ? (
                    <div className="desc-trial-in">{itm.source_study_no}</div>
                  ) : (
                    <div>-</div>
                  )}
                </div>
                <div className="ct-protocolid trial-list-row">
                  <div className="title-trial-in">Clinical ID :</div>
                  {itm.clinical_ids ? (
                    <div className="desc-trial-in">{itm.clinical_ids.join(', ')}</div>
                  ) : (
                    <div>-</div>
                  )}
                </div>
                <div className="ct-protocolid trial-list-row">
                  <div className="title-trial-in">Protocol ID :</div>
                  {itm.protocol_id.length ? (
                    <div className="desc-trial-in">{itm.protocol_id}</div>
                  ) : (
                    <div>-</div>
                  )}
                </div>
                <div className="ct-protocolid trial-list-row">
                  <div className="title-trial-in">LandSCAPE ID :</div>
                  {itm.landscape_id ? (
                    <div className="desc-trial-in">{itm.landscape_id}</div>
                  ) : (
                    <div>-</div>
                  )}
                </div>
                <div className="ct-protocolid trial-list-row">
                  <div className="title-trial-in">Enrolled Subjects :</div>
                  {itm.landscape_id ? (
                    <div className="desc-trial-in">{itm.enrollment_subjects}</div>
                  ) : (
                    <div>-</div>
                  )}
                </div>
                <div className="ct-protocolid trial-list-row">
                  <div className="title-trial-in">Parexel study ID :</div>
                  {itm.pxl_study_no ? (
                    <div className="desc-trial-in">{itm.pxl_study_no}</div>
                  ) : (
                    <div>-</div>
                  )}
                </div>
              </div>
              <div className="ct-sponsor trial-list-row">
                <div className="title-trial-in">Sponsors :</div>
                <div className="desc-trial-in">{itm.sponsors}</div>
              </div>
              <div className="ct-indication trial-list-row">
                <div className="title-trial-in">Indications :</div>
                <div className="desc-trial-in">{itm.indications.join(', ')}</div>
              </div>
              <div className="ct-centers trial-list-row">
                <div className="title-trial-in">Centers :</div>
                <div className="desc-trial-in">
                  <ReadMore type="text" limit={50}>
                    {itm.centers.join(', ')}
                  </ReadMore>
                </div>
              </div>
            </div>
          </Card>
        );
      }
      default:
        return (
          <>
            <div className="card">Default Template</div>
          </>
        );
    }
  };
  return (
    <>
      {returnTemplate()}
      <div>
        {openEmergingDataModal && (
          <Modal
            className="emerging-investigator-modal"
            title="TA wise Emerging KOL Class"
            open={openEmergingDataModal}
            onCancel={() => setEmergingDataModal(false)}
            destroyOnClose
            footer={null}
            style={{ minHeight: '500px' }}
          >
            <div className="emerging-investigator-title">
              <div className="emerging-investigator-ta">Primary TA : &nbsp;</div>
              {Object.keys(itm.emerging_data).length ? (<div className="emerging-investigator-"> {itm.emerging_data.therapeutic_area}</div>) : ''}
            </div>
            <div className="emerging-investigator-right">
              <div className="emerging-investigator-header">
                <div className="emerging-investigator-header-th theraprutic-area">
                  {' '}
                  Therapy Area{' '}
                </div>
                <div className="emerging-investigator-header-th"> Score </div>
                <div className="emerging-investigator-header-th"> Emerging KOL Class </div>
              </div>
              <div className="emerging-investigator-body">
                {itm.emerging_kol?.length
                  ? itm.emerging_kol?.map((itm1) => (
                    <>
                      <div className="emerging-investigator-header-tr">
                        <div
                          className="emerging-investigator-header-td therapeutic-area"
                          style={{ display: 'flex' }}
                        >
                          <div className="emerging-investigator-header-td">
                            {itm1.therapeutic_area}
                          </div>
                        </div>
                        <div className="emerging-investigator-header-td">
                          <div className="emerging-investigator-header-td score">{itm1.score}</div>
                        </div>
                        <div className="emerging-investigator-header-td">
                          <div className="emerging-investigator-flag">
                            {itm1.flag === 'Medium' ? (
                              <span className="Medium-flag" title="Stable" />
                            ) : itm1.flag === 'Low' ? (
                              <span className="Low-flag" title="Falling" />
                            ) : itm1.flag === 'High' ? (
                              <span className="High-flag" title="Rising" />
                            ) : (
                              ''
                            )}
                          </div>
                        </div>
                      </div>
                    </>
                  ))
                  : ''}
              </div>
            </div>
          </Modal>
        )}
      </div>
    </>
  );
};

export default Template;
